import React, { useState } from 'react';
import './css/settings.css';
import './css/style.css';

import logo from './img/logo.png';
import b1 from './img/b1.png';
import b2 from './img/b2.png';
import b3 from './img/b3.png';
import b4 from './img/b4.png';
import unnamed from './img/unnamed.jpg';
import youtube from './img/youtube.png';
import charImg from './img/char-img.jpg';
import panorama from './img/panorama.jpg';
import rev1 from './img/rev1.png';
import rev2 from './img/rev2.png';
import rev3 from './img/rev3.png';
import rev4 from './img/rev4.png';
import botcam from './img/botcam.png';
import car from './img/car.png';

function App() {
	return (
		<div>
			<head>
				<meta charset='utf-8' />
				<meta name='viewport' content='width=device-width' />
				<title>Зеркало - бортовой компьютер + видеорегистратор Fugicar</title>
				<meta
					name='description'
					content='Зеркало Fugicar FC8 — накладка на штатное зеркало заднего вида. Ведет запись в Full HD качестве с разрешением 1080 px. Картинка получается невероятно чёткая!'
				/>
				<link rel='shortcut icon' href='favicon.ico' />
				<link rel='stylesheet' href='assets_pages/land/css/reset.css' />
				<link
					rel='stylesheet'
					href='assets_pages/land/fonts/GothamPro/GothamPro.css'
					type='text/css'
				/>
				<link
					rel='stylesheet'
					href='assets_pages/land/js/slider/slick-1.8.0/slick.css'
				/>
				<link rel='stylesheet' href='css/settings.css' />
				<link rel='stylesheet' href='css/style.css?v=2' />
			</head>
			<body>
				<section className='block1'>
					<div className='wrap'>
						<div className='logo clearfix'>
							<img src={logo} alt='' />
							<div className='logo__text'>
								Производитель видеорегистраторов с 2012 года
							</div>
						</div>
						<ul className='tophint'>
							<li>
								выбор <b>100 тысяч</b> автолюбителей
							</li>
							<li>
								<b>3</b> года гарантии
							</li>
						</ul>
					</div>
				</section>
				<section className='block2'>
					<div className='wrap'>
						<div className='header-left'>
							<h1>Зеркало - бортовой компьютер</h1>
							<h2>+ видеорегистратор</h2>
							<ul className='bullits'>
								<li>
									<img src={b1} alt='' />
									<p>
										Много встроенных функций{' '}
										<span>и популярные приложения на базе Android</span>
									</p>
								</li>
								<li>
									<img src={b2} alt='' />
									<p>Постоянно в сети</p>
								</li>
								<li>
									<img src={b3} alt='' />
									<p>
										Уникальная технология{' '}
										<span>
											с использованием камеры регистратора
											<br /> в дневное и ночное время
										</span>
									</p>
								</li>
								<li>
									<img src={b4} alt='' />
									<p>
										Простая установка <span>на любую марку автомобиля</span>
									</p>
								</li>
							</ul>
						</div>
						<div className='sale'>
							скидка
							<p>77%</p>
						</div>
						<div className='mediaperson clearfix'>
							<div className='mediaperson__left'>
								<img src={unnamed} alt='' />
								<div>
									<p>
										AcademeG <span>рекомендует</span>
									</p>
									<span>4,49 млн подписчиков</span>
								</div>
							</div>
							<a href='#video-review' className='mediaperson__link'>
								<span>смотреть обзор</span> <img src={youtube} alt='' />
							</a>
						</div>
						<div className='action_desctop'>
							<h3>ЛЕТНЯЯ распродажа!</h3>
							<div className='action_desctop__price'>
								<div className='ad__old'>
									<b>9530</b>
									<span>р</span>
								</div>
								<div className='ad__action-cost'>
									Цена
									<br /> по акции:
								</div>
								<div className='ad_new'>
									2190<span>р</span>
								</div>
							</div>
							<a href='#order_form' className='btn'>
								заказать со скидкой
							</a>
						</div>
					</div>
				</section>
				<section className='block3'>
					<div className='wrap'>
						<h2>ОСЕННЯЯ распродажа!</h2>
						<ul className='sale-price'>
							<li>
								<span>9530</span>
								<small>р</small>
							</li>
							<li>Цена по акции:</li>
							<li>
								2190<small>р</small>
							</li>
						</ul>
						<a href='#order_form' className='btn'>
							заказать со скидкой
						</a>
					</div>
				</section>
				<section className='block4' id='video-review'>
					<div className='wrap'>
						<h2 className='title'>
							<span>Японский бортовой компьютер в стильном</span> ультратонком
							корпусе
						</h2>
						<div className='video-cont'>
							<div className='video-cont__header clearfix'>
								<div className='vc-header__name'>
									<img src={unnamed} alt='' />
									<span>AcademeG</span>
								</div>
								<div className='vc-header__followers'>4,49 млн подписчиков</div>
							</div>
							<div className='video-container shadow'>
								<div className='youtube' id='2HpwjHqV0aE'></div>
							</div>
							<p>
								Все оригинальные устройства «Fugicar» производятся на
								современном, высокотехнологичном оборудовании, что позволило нам
								создать уникальный,
								<b>ультратонкий корпус толщиной всего 8 мм</b>, заключивший в
								себя самый богатый внутренний функционал среди аналогов
							</p>
						</div>
					</div>
				</section>
				<section className='block5'>
					<div className='wrap'>
						<h2 className='title'>
							Почему Fugicar FC8 <br /> <span>лучше других регистраторов?</span>
						</h2>
						<div className='container clearfix'>
							<div className='cont-left'>
								<div className='properties'>
									<h3>Обычный регистратор</h3>
									<ul className='prop-ul'>
										<li>
											После заполнения памяти нужно самостоятельно удалять файлы
											и осуществлять перезапись.
										</li>
										<li>Синхронизировать с другими камерами невозможно.</li>
										<li>
											Не владеет таким широким кругом дополнительных функций.
										</li>
										<li>Требует ручного включения и отключения.</li>
									</ul>
								</div>
							</div>
							<div className='cont-right'>
								<div className='properties prop_trokot'>
									<h3>Fugicar FC8</h3>
									<ul className='prop-ul'>
										<li>
											Когда память заполняется, устройство удаляет наиболее
											старые материалы для сохранения новых.
										</li>
										<li>
											Способен работать в связке с несколькими камерами
											одновременно.
										</li>
										<li>
											Имеет датчик движения, систему «Антисон», радар-детектор и
											др.
										</li>
										<li>Включается с запуском машины.</li>
									</ul>
								</div>
							</div>
						</div>
						<a href='#order_form' className='btn'>
							заказать Fugicar <span>со скидкой</span>
						</a>
					</div>
				</section>
				<section className='block6'>
					<div className='wrap'>
						<h2 className='title'>
							<span>больше преимуществ</span> Fugicar FC8
						</h2>
						<div className='other-item oi-1 clearfix'>
							<div className='other-item__left'>
								<div className='other-item__shadow'></div>
								{/* <img src='img/other1.png' alt='' /> */}
							</div>
							<div className='other-item__right'>
								<h3>Простая установка</h3>
								<p>
									Устанавливается на штатном зеркале любой толщины, фиксируется
									с помощью двух резиновых держателей.
								</p>
							</div>
						</div>
						<div className='other-item oi-2 clearfix'>
							<div className='other-item__left'>
								<div className='other-item__shadow'></div>
								{/* <img src='img/other2.png' alt='' /> */}
							</div>
							<div className='other-item__right'>
								<h3>Стильный дизайн</h3>
								<p>
									Качественные материалы и стильный дизайн делают прибор
									непревзойденным в своём классе.
								</p>
							</div>
						</div>
						<div className='other-item oi-3 clearfix'>
							<div className='other-item__left'>
								<div className='other-item__shadow'></div>
								{/* <img src='img/other3.png' alt='' /> */}
							</div>
							<div className='other-item__right'>
								<h3>Запись в режиме реального времени</h3>
								<p>Видеозапись в Full HD качестве.</p>
							</div>
						</div>
						<div className='other-item oi-4 clearfix'>
							<div className='other-item__left'>
								<div className='other-item__shadow'></div>
								<img src='img/other4.png' alt='' />
							</div>
							<div className='other-item__right'>
								<h3>Широкий угол обзора</h3>
								<p>
									Видеть всё что происходит вокруг, благодаря объёмному углу
									обзора в 140°.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className='block7'>
					<div className='wrap'>
						<h2 className='title'>Отзывы</h2>
						<div className='reviews'>
							<div className='rev'>
								<img src={rev1} alt='' />
								<p>
									Андрей
									<br />
									<span>Сделал себе такой. Всё работает. Мне нравится!</span>
								</p>
							</div>
							<div className='rev'>
								<img src={rev2} alt='' />
								<p>
									Татьяна
									<br />
									<span>Супер качество! Очень довольна!</span>
								</p>
							</div>
							<div className='rev'>
								<img src={rev3} alt='' />
								<p>
									Евгений
									<br />
									<span>Крутой девайс! Рекомендую всем!</span>
								</p>
							</div>
							<div className='rev'>
								<img src={rev4} alt='' />
								<p>
									Светлана
									<br />
									<span>Полезная штука. Сохраняет спокойствие на дороге!</span>
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className='block8'>
					<div className='wrap'>
						<h2 className='title'>Запись</h2>
						<p>
							С помощью Fugicar FC8 ваша безопасность на дороге — это не просто
							слова, а реальность!
						</p>
						<a href='#order_form' className='btn'>
							заказать Fugicar <span>со скидкой</span>
						</a>
					</div>
				</section>
				<section className='block9'>
					<div className='wrap'>
						<h2 className='title'>Преимущества</h2>
						<ul className='prop'>
							<li>
								{/* <img src={botcam} alt='' /> */}
								<span>Система записи видео в высоком разрешении</span>
							</li>
							<li>
								{/* <img src={car} alt='' /> */}
								<span>Работа с различными марками автомобилей</span>
							</li>
						</ul>
					</div>
				</section>
				<footer>
					<div class='footer__links'>
						<p>
							{' '}
							<a href='politics.html'>Политика конфиденциальности</a>
						</p>
						<p>
							{' '}
							<a href='agreement.html'>Пользовательское соглашение</a>
						</p>
						<p>
							<a href='#' class='_popup-link' data-target='returnPolicy'>
								Условия возврата товара
							</a>
						</p>
					</div>
					<div>
						ООО «ФУДЖИКАМ РУ», ОГРН 1165476114099 ИНН 5403017990, КПП 540501001,
						630009 г. Новосибирск, ул Добролюбова, д. 2а, офис 104/3 +7 (383)
						762‒33‒23 email: moikaru@info.ru<div class='user_contacts'></div>
					</div>
					<div className='wrap'>
						<div className='footer__left'>
							<img src={logo} alt='' />
							<p>© 2024 Fugicar. Все права защищены.</p>
						</div>
					</div>
				</footer>
			</body>
		</div>
	);
}

export default App;
